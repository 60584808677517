/*
 * Created by Paul Engelke on 22 February 2021.
 */

import {LocalCache, LocalCacheRegistry} from '@hti-ui/local-cache';
import HttpManager from "../util/httpManager";
import CustomerActionTypes from "../constants/action-types/customerActionTypes";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * The modelled entity on the server, for which all operations below are
 * performed.
 * @type {string}
 */
const entity = 'customer';

/**
 * Fetches a list of customers.
 *
 * @param {Object} args The function arguments, including request parameters.
 * @param {boolean} [args.force=false] Should local cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchCustomers = (args) => (dispatch) => {

  const {force = false} = args;
  const key = 'all-customers';

  if (!force && cache.has(key)) {
    return cache.get(key);
  }

  dispatch({type: CustomerActionTypes.REQUEST});
  const request = HttpManager.get({entity});

  return request
  .then(r => {
    dispatch({type: CustomerActionTypes.SET, data: r?.data?.data ?? []});
    return r;
  })
  .catch(e => {
    cache.delete(key);
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  })

};

/**
 * Fetches a list of customers.
 *
 * @param {Object} args The function arguments, including request parameters.
 * @param {boolean} [args.force=false] Should local cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchCustomersPaged = (page, pageSize, args) => (dispatch) => {

  // console.log(`fetchCustomersPaged: page:${page} pageSize${pageSize}`)
  // const {force = false} = args;

  // Should paged results even be cached?
  // const key = 'paged-customers';

  // if (!force && cache.has(key)) {
  //   return cache.get(key);
  // }

  dispatch({type: CustomerActionTypes.REQUEST});
  const request = HttpManager.get({entity, payload: {page, pageSize}});

  // cache.clear();
  // cache.set(key, CacheLifetime.SHORT, request);

  return request
  .then(r => {
    const data = r?.data?.data ?? [];
    dispatch({type: CustomerActionTypes.SET, data: data});
    return data;
  })
  .catch(e => {
    // cache.delete(key);
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  })

};

/**
 * Creates a new customer.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.name The new customer's name.
 * @return {function(*,*): Promise}
 */
export const createCustomer = (args) => (dispatch) => {
  const {name ,apiKey} = args;
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.post({entity, payload: {name, apiKey}})
  .then(r => {
    dispatch({type: CustomerActionTypes.CREATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing customer.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.name The customer's new name.
 * @return {function(*,*): Promise}
 */
export const updateCustomer = (args) => (dispatch) => {
  const {id, name, apiKey} = args;
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.put({entity, payload: {id, name, apiKey}})
  .then(r => {
    dispatch({type: CustomerActionTypes.UPDATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes an existing customer.
 *
 * @param {Object} args The request parameters.
 * @param {number} args.id The customer's ID.
 * @return {function(*,*): Promise}
 */
export const deleteCustomer = (args) => (dispatch) => {
  const {id} = args;
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.delete({entity, payload: {id}})
  .then(r => {
    dispatch({type: CustomerActionTypes.DELETE, data: {id}});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};
